import * as Sentry from "@sentry/react";

export const initSentry = () => {
    if (process.env.REACT_APP_DISABLE_SENTRY) return;

    Sentry.init({
        dsn: "https://ba548413ac01b12eafa90a4794216575@o4508061226106880.ingest.de.sentry.io/4508061238362192",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/.*\.territory\.dev\//],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
}
