import _ from 'lodash'
import Grid from '@mui/material/Unstable_Grid2'
import { useState, useEffect, useContext, useMemo, useCallback } from 'react'

import { maps } from '../../api/api'
import { UserContext } from '../../contexts/userContext'

import styles from './RepoCatalog.module.css'
import { Link } from 'react-router-dom'


export const RepoCatalog = ({createMap}) => {
    const {
        user,
    } = useContext(UserContext)

    const [repos, setRepos] = useState(null)

    const reposLists = useMemo(() => {
        const result = {
            my: [],
            sharedWithMe: [],
            public: [],
        }

        if (!repos) return result;

        for (let doc of repos) {
            let data = doc;
            let dest;
            if (data.public)
                dest = result.public;
            else if (data.owner == user.uid)
                dest = result.my;
            else if (_.includes(data.sharedWithUsers, user.uid))
                dest = result.sharedWithMe;
            else
                console.error("received a repo that we shouldn't see", doc, data);

            dest.push(doc)
        }
        return result
    }, [repos])

    useEffect(() => {
        maps.getReposWithBuilds().then((resp) => setRepos(resp))
    }, [])

    const tiles = (header, list) => <>
        <h1>{header}</h1>
        <Grid container spacing={2}>
            {list.map(repo => <Grid key={repo.id} xs={4} justifyContent="center" alignItems="center">
                <a href="#" onClick={() => createMap(repo.id, repo)} style={{height: "100px"}}>
                    {repo.user_name && (repo.user_name + ' / ')}
                    {repo.name}
                </a>
            </Grid>)}
        </Grid>
    </>

    return <>
        <div className={styles.repoCatalog}>
            {(reposLists.my?.length > 0) && tiles('My repositories', reposLists.my)}
            {(reposLists.sharedWithMe?.length > 0) && tiles('Shared with me', reposLists.sharedWithMe)}
            {tiles('Public repositories', reposLists.public)}
        </div>

        <div className={styles.buildsNote}>
            Don't see the repo you are looking for? <Link to="/repos/new">Import it here</Link>.
        </div>
    </>
}
