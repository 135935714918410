import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { maps } from '../../api/api'
import { Loader } from '../../components/Loader'
import { Layout } from '../../components/Layout'
import { MapsContext } from '../../contexts/mapsContext'
import { UserContext } from '../../contexts/userContext'

import { MapsList } from './MapsList'
import { CreateMap } from './CreateMap'
import { RemoveMap } from './RemoveMap'
import { CreateMapButton } from './CreateMapButton'
import { RepoCatalog } from './RepoCatalog'

export const Dashboard = () => {
    const {
        user,
    } = useContext(UserContext)

    return (user)
        ? <UserDasboard />
        : <PublicDashboard />;
}


export const PublicDashboard = () => {
    const navigate = useNavigate()

    const createMap = async (repoId, repo) => {
        const branch = repo.defaultBranch || (await maps.getBranches(repoId))[0].id
        const builds = await maps.getBuilds(repoId, branch)
        const buildId = builds[0].id

        const encBranch = encodeURIComponent(branch)
        navigate(`/maps/local/${repoId}/${encBranch}/${buildId}`)
    }

    return (
        <Layout scrollable legalFooter>
            <RepoCatalog createMap={createMap}/>
        </Layout>
    )
}


export const UserDasboard = () => {
    const {
        mapsList,
        mapsLoading,
        reloadMaps,
    } = useContext(MapsContext)

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [mapToRemove, setMapToRemove] = useState(null)
    const [selectedRepo, setSelectedRepo] = useState(null)

    useEffect(() => { reloadMaps() }, [])

    const createMap = (repoId) => {
        setSelectedRepo(repoId)
        setIsModalOpen(true)
    }

    return (
        <Layout scrollable legalFooter>
                <CreateMapButton setIsModalOpen={setIsModalOpen}></CreateMapButton>
               {mapsLoading
                    ? <Loader />
                    : (mapsList.length == 0)
                        ? null
                        : <MapsList maps={mapsList} setRemoveMap={setMapToRemove} />
                }
                <RepoCatalog createMap={createMap}/>
                {isModalOpen && (
                    <CreateMap
                        closeModal={() => setIsModalOpen(false)}
                        mapsList={mapsList}
                        open={isModalOpen}
                        selectedRepo={selectedRepo}
                        setSelectedRepo={setSelectedRepo}
                    />
                )}
                {mapToRemove ? (
                    <RemoveMap
                        close={() => setMapToRemove(null)}
                        reloadMaps={reloadMaps}
                        mapToRemove={mapToRemove}
                    />
                ) : null}
        </Layout>
    )
}
